:root {
  --border-radius-full: 9999px;
}

@mixin full {
  border-radius: var(--border-radius-full) !important;
}

@mixin l-full {
  border-top-left-radius: var(--border-radius-full);
  border-bottom-left-radius: var(--border-radius-full);
}
