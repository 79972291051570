app-question-dialog {
  .mat-radio-label-content {
    white-space: normal;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--eco-portal-primary-color);
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--eco-portal-primary-color) !important;
  }
}
