app-kpi-progress-bar {
  .progress-bar {
    &.small-shadow {
      box-shadow: 0 1px 2px 0 hsl(0 0% 84%);
    }
  }

  .value {
    &.white {
      color: white;
    }

    &.big {
      font-size: 20px;
      color: #423e3d;
    }

    &.light {
      font-family: sfuidisplayLight, sans-serif;
    }
  }
}
