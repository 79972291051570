// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as mat;
@use 'vars';
@use 'fonts';
@use 'components';
@use 'rounded';
@use 'animations';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nyx2021-primary: mat.define-palette(mat.$indigo-palette);
$nyx2021-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$nyx2021-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$nyx2021-theme: mat.define-light-theme((
  color: (
    primary: $nyx2021-primary,
    accent: $nyx2021-accent,
    warn: $nyx2021-warn,
  )
));

@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');

@import '~lightgallery/scss/lightgallery';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($nyx2021-theme);

@import 'node_modules/swiper/swiper';
@import 'node_modules/swiper/modules/zoom/zoom';
@import 'node_modules/swiper/modules/navigation/navigation';
@import 'node_modules/swiper/modules/pagination/pagination';
@import 'node_modules/swiper/modules/free-mode/free-mode';
@import 'node_modules/swiper/modules/thumbs/thumbs';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  outline: none;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

button {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

html, body {
  max-width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
  line-height: 1.6;
  font-family: "sfuidisplayMedium", Helvetica, sans-serif;
  color: #000000;
  background-color: #ffffff;
}

.html_text {
  margin: 0 0 50px 0;
  font-family: montRegular, sans-serif;
  font-size: 14px;
  &.title {
    font-family: montBold, sans-serif;
    font-size: 25px;
  }
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
  h1, h2 {
    padding: 0 0 30px 0;
  }
  h3, h4, h5, h6 {
    padding: 0 0 20px 0;
  }
  p {
    padding: 0 0 20px 0;
    a {
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  ol {
    list-style: decimal;
    list-style-position: inside;
    padding: 0 0 20px 0;
  }
  ul {
    list-style: disc;
    list-style-position: inside;
    padding: 0 0 20px 0;
  }
  table {
    td {
      padding: 3px;
      border: 1px solid #000000;
    }
  }
}

.scroll {
  scrollbar-width: thin;

  &:hover {

    &::-webkit-scrollbar-thumb {
      display: block;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    border-radius: 20px;
    background-color: darkgrey;
  }
}

.dialog-bg {
  background: inherit;
}

.backdrop {
  background: rgba(0, 0, 0, 0.672)
}

.bread_and_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  @media screen and (max-width: 1300px) {
    padding: 15px 0;
  }
  @media screen and (min-width: 1024px) and (max-height: 720px) {
    padding: 15px 0;
  }
  .bread_and_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.game_moder {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .page_title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 25px;
    color: #000;
    cursor: pointer;
  }
  .icon {
    width: 23px;
    height: 23px;
    &.back {
      position: relative;
      width: 15px;
      height: 32px;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        width: 16px;
        height: 16px;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        transform:rotate(-135deg);
      }
    }
  }
}

.breadcrumbs {
  font-size: 16px;
  line-height: 30px;
  color: #000;
  margin: 0 0 20px 0;
  @media screen and (max-width: 1300px) {
    margin: 0 0 20px 0;
  }
  @media screen and (min-width: 1024px) and (max-height: 720px) {
    margin: 0 0 20px 0;
  }
  .crumb {
    display: inline-block;
    .crumb_title {
      display: inline-block;
      &.link {
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .separator {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  font-family: "sfuidisplayHeavy", sans-serif;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  font-size: 19px;
  color: #B09D8C;
  font-weight: bold;
  transition: all .2s linear;
  cursor: pointer;
  position: relative;
  margin: 3px 0;
  @media screen and (max-width: 550px) {
    text-align: center;
  }

  .ar {
    content: '';
    width: 45px;
    height: 45px;
    background: #B09D8C;
    border-radius: 100%;
    position: absolute;
    right: 14px;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      margin: 0 0 0 11px;
      transform:rotate(45deg);
    }
  }

  &.inactive {
    cursor: default;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  &:hover {
    background: #ffffff;
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
  }
}

.outer {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  padding: 0;
  margin: 0 auto;
  background: url("/assets/images/main_bg.png") center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  &.auth {
    background-size: cover;
    @media screen and (max-width: 670px) {
      background: rgb(226 225 230)!important;
    }
  }
  &.auth_reg {
    justify-content: center;
    background-size: cover;
    @media screen and (max-width: 670px) {
      background: rgb(226 225 230)!important;
    }
    @media screen and (max-width: 450px) {
      min-height: 900px!important;
    }
  }
  &.catalog {
    background: url("/assets/images/catalog_bg.png") center center no-repeat;
    background-size: cover;
    .workspace {
      padding: 80px 0 0;
      @media screen and (max-width: 1600px) {
        padding: 80px 0 0;
      }
      @media screen and (max-width: 768px) {
        padding: 80px 0 0;
      }
      @media screen and (max-width: 500px) {
        padding: 80px 0 0;
      }
    }
  }
  &.card {
    background-color: #fff;
    background-image: none;
  }
  &.game {
    background-color: #fff;
    background-image: none;
  }
  .workspace {
    width: 100%;
    padding: 80px 160px 0;
    @media screen and (max-width: 1600px) {
      padding: 80px 60px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 80px 30px 0;
    }
    @media screen and (max-width: 500px) {
      padding: 80px 10px 0;
    }
    &.main {
      padding: 0;
    }
    &.document {
      padding: 80px 375px 0;
      @media screen and (max-width: 1600px) {
        padding: 80px 90px 0;
      }
      @media screen and (max-width: 768px) {
        padding: 80px 0 0 0;
      }
    }
    &.newest {
      display: flex;
      justify-content: flex-end;
      gap: 100px;
      padding: 67px 0 0 0;
      background: rgba($color: #000000, $alpha: .7);
      @media screen and (max-width: 1600px) {
        gap: 50px;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        gap: 10px;
      }
    }
    &.game_moder_column {
      display: flex;
      flex-direction: column;
    }
    &.seasons {
      padding: 0 0 0 120px;
      @media screen and (max-width: 1600px) {
        padding: 0 40px 0 40px;
      }
      @media screen and (max-width: 425px) {
        padding: 0 20px 0 20px;
      }
    }
    &.personal {
      display: flex;
      padding: 80px 300px 0 60px;
      // @media screen and (max-width: 1600px) {
      //   gap: 50px;
      // }
      @media screen and (max-width: 1200px) {
        // gap: 100px;
        justify-content: center;
      }
      @media screen and (max-width: 1024px) {
        // gap: 30px;
        // justify-content: center;
        // flex-direction: column-reverse;
        padding: 160px 40px 60px;
      }
      @media screen and (max-width: 450px) {
        // gap: 30px;
        // justify-content: center;
        // flex-direction: column-reverse;
        padding: 160px 20px 60px;
      }
    }
    &.contests {
      padding: 80px 350px 0;
      background: rgba($color: #000000, $alpha: .7);
      @media screen and (max-width: 1600px) {
        padding: 67px 90px 0;
      }
      @media screen and (max-width: 1024px) {
        padding: 67px 35px 0;
      }
      @media screen and (max-width: 768px) {
        padding: 67px 30px 0;
      }
      @media screen and (max-width: 500px) {
        padding: 67px 10px 0;
      }
    }
    &.profile {
      display: flex;
      gap: 100px;
      padding: 35px 0 0 0;
      height: 100%;
      @media screen and (max-width: 1600px) {
        gap: 50px;
      }
      @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
      }
    }
    &.statistics {
      display: flex;
      flex-direction: column;
      padding: 35px 0 20px 0;
      height: 100%;
      @media screen and (max-width: 1300px) {
        gap: 50px;
        justify-content: center;
      }
      @media screen and (max-width: 1024px) {
        gap: 0;
        justify-content: center;
      }
    }
    &.team {
      display: flex;
      flex-direction: column;
      padding: 35px 0 0 0;
      height: 100%;
      @media screen and (max-width: 1300px) {
        width: 95%;
        padding: 35px 300px 0 20px;
      }
      @media screen and (max-width: 1200px) {
        width: 83%;
        padding: 35px 0 0 145px;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
        padding: 0px 10px 285px 10px;
      }
      @media screen and (max-width: 950px) {
        padding: 0 10px 0 10px;
      }
    }
    &.infographics {
      display: flex;
      flex-direction: column;
      padding: 35px 0 0 0;
      height: 100%;
      @media screen and (max-width: 1300px) {
        width: 100%;
        padding: 35px 0px 0 20px;
      }

    }
    &.calendar {
      display: flex;
      padding: 35px 0 0 0;
      height: 100%;
    }
    &.important {
      display: flex;
      justify-content: flex-end;
      padding: 80px 0 0 0;
      background: rgba($color: #000000, $alpha: .7);
      gap: 10px;
      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
      }
    }
    &.career {
      padding: 0 0 0 180px;
      @media screen and (max-width: 1600px) {
        padding: 0 0 0 80px;
      }
      @media screen and (max-width: 375px) {
        padding: 0 0 0 40px;
      }
    }
    &.video {
      display: flex;
      justify-content: flex-end;
      padding: 80px 0 0 0;
      background: rgba($color: #000000, $alpha: .7);
      gap: 40px;
      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        gap: 10px;
      }
    }
    &.video_brand {
      padding: 0 0 0 40px;
    }
  }
}

app-newest-list, app-trainings-list, app-library-list, app-important-list, app-video-list, app-marketing-list, app-reports, app-infographics {
  width: 100%;
}

app-reports, app-infographics {
  height: 100%;
}

// Авторизация
app-auth, app-game-moderator-rules {
  .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
    top: -1px;
    position: relative;
    background-color: #ffffff;
    border: 2px solid #B09D8C;
    border-radius: 20px;
    .mat-checkbox-frame {
      border:none;
    }
    .mat-checkbox-background {
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: 3px;
    }
    .mat-checkbox-background svg {
      display: none;
    }
  }
}

// .mat-ripple-element  {
//   background: #B09D8C !important;
// }

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #B09D8C !important;
}
// Авторизация (конец)

/* Диалоговые окна */
.mat-dialog-container {
  padding: 0 !important;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px !important;
}

.custom_dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  padding: 40px 25px 45px;
  ul {
    list-style: disc;
    li {
      font-size: 16px;
    }
  }
  &.video-test {
    padding: 15px;
  }
  &.user_change {
    justify-content: flex-start;
    align-items: flex-start;
    width: 500px;
    padding: 15px;
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    @media screen and (max-width: 500px) {
      width: 280px;
    }
    @media screen and (max-width: 380px) {
      width: 240px;
    }
  }
  &.management_dialog_form {
    display: block;
    width: 900px;
    height: 900px;
    background: #e7e6e6;
    padding: 0;
    @media screen and (max-width: 1440px) {
      height: 650px;
    }
    @media screen and (max-width: 1024px) {
      width: 850px;
    }
    @media screen and (max-width: 768px) {
      width: 620px;
    }
    @media screen and (max-width: 425px) {
      width: 340px;
    }
    @media screen and (max-width: 375px) {
      width: 300px;
    }
    @media screen and (max-width: 325px) {
      width: 250px;
    }
  }
  &.game {
    width: 1000px;
    height: 610px;
    p {
      font-size: 16px;
    }
    @media screen and (max-width: 1300px) {
      width: 780px;
    }
    @media screen and (max-width: 900px) {
      width: 500px;
    }
    @media screen and (max-width: 500px) {
      width: auto;
      height: 90vh;
      overflow: auto;
      padding: 20px 25px 45px;
      p {
        font-size: 12px;
      }
    }
  }
  &.booking {
    display: flex;
    align-items: start;
    width: 440px;
    padding: 20px 30px;
    @media screen and (max-width: 500px) {
      width: auto;
      height: auto;
    }
    &.create {
      flex-direction: column;
      width: 500px;
      gap: 20px;
      @media screen and (max-width: 500px) {
        width: auto;
        height: auto;
      }
    }
  }
  &.game_moderator {
    display: flex;
    align-items: start;
    width: 800px;
    padding: 20px 20px;
    @media screen and (max-width: 1200px) {
      width: 690px;
    }
    @media screen and (max-width: 1100px) {
      width: 630px;
    }
    @media screen and (max-width: 768px) {
      width: auto;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    width: auto;
    height: auto;
  }
}

.mat-dialog-title {
  font-family: montBold, sans-serif !important;
}

.dialog-title {
  font-family: montBold, sans-serif;
  font-size: 20px;
  text-align: center;
  &.game_moderator {
    text-align: start;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
  }
  &.game_moderator_popup {
    text-align: start;
    font-family: montRegular, sans-serif !important;
    font-size: 18px;
    margin-top: 30px;
  }
}

.dialog-text {
  font-family: montRegular, sans-serif;
  font-size: 20px;
  @media screen and (max-width: 320px) {
    font-size: 17px;
  }
}

.dialog-content {
  text-align: center;
  .input-container {
    margin: 0 0 25px 0;
    width: 100%;
    justify-self: auto;
    display: inline-block;
    vertical-align: top;
    font-family: montBold, sans-serif;
    @media only screen and (max-width: 520px) {
      margin: 0 0 15px 0;
    }
    input, textarea {
      color: #000000;
      outline: none;
      width: 100%;
      height: 50px;
      font-size: 16px;
      line-height: 50px;
      padding: 0 24px;
      border: 2px solid #d4d4d4;
      &.invalid-input {
        border-color:#FF0000;
      }
      &.edit-input {
        border-color: green;
        color: green;
      }
      @media only screen and (max-width: 520px) {
        height: 40px;
        font-size: 14px;
        line-height: 40px;
        padding: 0 10px;
      }
    }
    .error {
      padding: 5px 0 0 0;
      color: red;
      @media screen and (max-width: 425px) {
        font-size: 17px;
      }
    }
  }
  &.game {
    width: 370px;
    height: 370px;
    padding: 0;
    margin: 0;
    @media screen and (max-width: 900px) {
      width: 350px;
      height: 350px;
    }
    @media screen and (max-width: 500px) {
      width: 280px;
      height: 280px;
    }
    @media screen and (max-width: 380px) {
      width: 250px;
      height: 250px;
    }
  }
  &.game_moderator {
    text-align: start;
    font-weight: bold;
    font-size: 16px;
  }
}

.dialog-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  &.user_change_actions {
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    align-items: center;
    flex-direction: column;
  }
}

.dialog_button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 190px;
  min-height: 45px;
  line-height: normal;
  font-family: montBold, sans-serif;
  font-size: 20px;
  color: #AF9D8E;
  border-radius: 30px;
  text-align: center;

  cursor: pointer;
  transition: all .2s linear;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 4px 6px 8px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #ffffff;
    color: #000000;
  }
  @media screen and (max-width: 425px) {
    font-size: 17px;
  }
  &.user_change_button {
    margin: 0 auto;
    border: 1px solid #000000;
  }
  &.purchase {
    width: 230px;
    background: #ffffff;
  }
  &.purchase_finish {
    width: 130px;
    background: #ffffff;
  }
}

/* Диалоговое окно (команда) */
.team_dialog_container {
  position: relative;
  overflow: auto;
  width: 1200px;
  height: 830px;
  border: 5px solid #ffffff;
  border-radius: 0;
  padding: 20px;
  background: #ffffff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  @media screen and (max-width: 1600px) {
    width: 1000px;
    height: 630px;
  }
  @media screen and (max-width: 1300px) {
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

/* Диалоговое окно (команда) (конец) */

/* Диалоговые окна (конец) */

/* Настройки свайпера */

.custom_swiper_for_document {
  .swiper-button-prev {
    top: 95%;
    left: 20%;
  }
  .swiper-button-next {
    top: 95%;
    right: 20%;
  }
}

.custom_swiper_for_catalogs_card {
  width: 365px;
  height: 360px;
  margin: 0;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.custom_swiper_thumbs_for_catalogs_card {
  margin: 0;
  .swiper-slide {
    width: 55px;
    height: 55px !important;
    opacity: 0.4;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.custom_swiper_thumbs {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
  .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-slide-thumb-active {
    opacity: 1;
  }
}

.custom_swiper {
  width: 100%;
  height: 50%;
  margin: 0;
  @media screen and (max-width: 670px) {
    height: 40%;
  }
  .swiper-wrapper {
    .swiper-slide {
      .swiper_image {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.swiper-pagination-bullet {
  background: rgba($color: #2D2D2D, $alpha: .7);
  width: 22px;
  height: 5px;
  border-radius: 5px;
  &.swiper-pagination-bullet-active {
    background-color: #8A6C91;
  }
}


/* Настройка свайпера (конец) */

/* Скроллер в статистике */
.scroll {
  //padding: 0 45px;
  @media screen and (max-width: 1300px) {
    padding: 0 0;
  }
}
/*Скроллер в статистике (конец) */

/*Скролл в таблице инфографики */
.table_scroll {
  height: 400px !important;
  &.booking {
    height: 1250px !important;
  }
}

/* Табы в тренингах */
.custom_tabs {
  &.training {
    .mat-tab-label-container {
      .mat-ink-bar {
        display: none;
      }
    }
    .mat-tab-labels {
      flex-wrap: wrap;
      gap: 40px;
      @media screen and (max-width: 425px) {
        display: grid;
      }
      .mat-tab-label-active {
        background: #ffffff;
        .mat-tab-label-content {
          color: #000000;
        }
      }
      .mat-tab-label {
        min-width: 240px;
        height: 50px;
        opacity: 1;
        border: 5px solid #ffffff;
        font-family: sfuidisplayBlack, sans-serif;
        font-size: 16px;
        text-transform: uppercase;
        color: #ffffff;
        transition: all .2s linear;
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
        .counter {
          margin: 0 10px;
        }
        &:hover {
          background: #ffffff;
          .mat-tab-label-content {
            color: #000000;
          }
        }
      }
    }
  }
  &.infographics_main_tg {
    .mat-tab-list {
      .mat-ink-bar {
        display: none;
      }
    }
    .mat-tab-labels {
      gap: 20px;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      .mat-tab-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 330px;
        height: 60px;
        background: #B09D8C;
        opacity: 1;
        font-family: montBold, sans-serif;
        font-size: 20px;
        color: #ffffff;
        transition: all .2s linear;
        cursor: pointer;
        &:hover {
          background: #ffffff;
          color: #000000;
        }
        &.mat-tab-label-active {
          color: #B09D8C;
          background: #ffffff;
          box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
        }
        @media screen and (max-width: 500px) {
          width: 200px;
          font-size: 13px;
        }
      }
    }
  }
  &.infographics {
    margin: 20px 0 0 0;
    .mat-tab-labels {
      gap: 10px;
    }
    .mat-tab-label {
      width: 160px !important;
      height: 60px;
      @media screen and (max-width: 900px) {
        width: 330px !important;
      }
      @media screen and (max-width: 500px) {
        width: 200px !important;
      }
    }
    .mat-tab-body {
      .mat-tab-body-content {
        overflow: hidden;
      }
    }
  }
}
/* Табы в тренингах (конец) */

/* Тесты */
.options-group {
  .options-DropList {
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, .15);
    cursor: pointer;
    .number {
      border-radius: 100%;
      border: 1px solid #000;
      width: 25px;
      height: 25px;
      margin: 0 20px 0 0;
      line-height: 23px;
      display: block;
      text-align: center;
      color: #000000;
    }
    .text {
      color: #000000;
      &.disabled {
        color: rgba($color: #000000, $alpha: .5);
        cursor: default;
      }
    }
  }

  .correct {
    background: lightgreen;
    cursor: default;
  }

  .incorrect {
    background: lightcoral;
    cursor: default;
  }

  .options-button {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
    color: #505050;
    font-size: 14px;
    position: relative;

    .mat-radio-outer-circle {
      border-color: #B09D8C !important;
      height: 25px;
      width: 25px;
    }

    .mat-radio-inner-circle {
      height: 25px;
      width: 25px;
      background-color: #B09D8C !important;
    }
    .mat-radio-label, .mat-checkbox-layout{
      white-space: normal;
      display: block;
      padding: 5px 0;
      .mat-radio-container, .mat-checkbox-inner-container {
        position: absolute;
        top: 50%;
        height: 25px;
        width: 25px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
      .mat-radio-label-content, .mat-checkbox-label {
        padding-left: 50px;
        display: block;
        line-height: 1.5;
      }
    }
  }
  &.for_admin {
    .options-button {
      width: 30px;
      margin: 0;
      .mat-radio-label, .mat-checkbox-layout {
        padding: 0;
      }
    }
  }
}

.options-textarea {
  border-radius: 3px;
  background-color: #A9A9A9;
  padding: 30px;
  textarea {
    width: 100%;
    height: 100px;
    padding: 20px;
    resize: none;
  }
}

/* Кастомный слайдер для тестов */
.custom-slider {
  margin-top: 10px;
  .mat-slider-wrapper {
    .mat-slider-track-wrapper {
      .mat-slider-track-fill {
        background-color: red;
      }
    }
    .mat-slider-thumb-container {
      .mat-slider-thumb-label {
        background-color: red;
      }
      .mat-slider-thumb {
        background-color: red;
      }
    }
  }
}
/* Кастомный слайдер для тестов (конец) */

/* Кастомный таб для просмотра ответов */
.custom_attempts_tab {
  font-family: "CenturyGothic", sans-serif;
  .mat-tab-header {
    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            .mat-tab-label-content {
              font-family: "CenturyGothic", sans-serif;
            }
          }
        }
      }
    }
  }
  .mat-ink-bar {
    background-color: #000000 !important;
  }
}
/* Кастомный таб для просмотра ответов (конец) */
/* Тесты(конец) */

.custom_date {
  display: flex;
  align-items: center;
  height: 50px;
  border: 5px solid #ffffff;
  padding: 0 10px;
  background: #000000;
  font-family: montBold, sans-serif;
  mat-datepicker-toggle {
    color: #ffffff;
  }
  mat-date-range-input {
    color: #ffffff;
    .mat-date-range-input-start-wrapper, .mat-date-range-input-end-wrapper {
      input {
        cursor: default;
      }
    }
    .mat-date-range-input-container {
      .mat-date-range-input-separator {
        color: #ffffff;
        cursor: default;
      }
    }
  }
  &.for_infographics {
    width: 250px;
    @media screen and (max-width: 900px) {
      width: 50%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

.reg_select{
  width: 100%;
  height: 50px;
  border: none;
  padding: 0 24px;
  background: rgba(255, 255, 255, 0.7);
  font-family: "sfuidisplayThin", sans-serif;
  box-shadow: 4px 6px 8px 1px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  border-radius: 30px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:focus {
    box-shadow: 4px 6px 8px 1px rgba(0, 0, 0, 0.1);
  }
  &:focus-visible {
    outline: none;
  }

  .mat-select-placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.custom_select {
  height: 50px;
  padding: 0 10px;
  color: #B09D8C;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 4px 6px 8px 1px rgba(0, 0, 0, 0.2);

  .mat-select-value {
    font-family: montBold, sans-serif !important;
    color: #B09D8C !important;
  }
  .mat-select-placeholder {
    font-family: montBold, sans-serif !important;
    color: #B09D8C !important;
  }


  .mat-select-trigger {
    height: 100%;
  }
  .mat-select-value {
    vertical-align: middle;
  }

  &.disabled {
    pointer-events: none;
    background: #dbdbdb;
  }
  &.for_team {
    width: 240px;
    @media screen and (max-width: 1600px) {
      width: 170px;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  &.for_infographics {
    width: 160px;
    @media screen and (max-width: 900px) {
      width: 50%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  &.for_booking {
    width: 100%;
    height: 35px;
    color: #000000;
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 15px;
    .mat-select-placeholder {
      font-family: montRegular, sans-serif !important;
      color: #777 !important;
    }
  }
  &.for_eco_gallery {
    width: 100%;
    color: #000000;
    .mat-select-placeholder {
      font-family: montBold, sans-serif !important;
      color: #adadad !important;
    }
  }
}

/* Catalog */

.tabs {
  .slick-track {
    width: 100%;
    position: relative;
  }
  .slick-prev, .slick-next {
    background-color: #fff;
    border: solid 1px #BFB0A5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align:center;
    z-index: 1;
    @media(max-width:600px) {
      height: 20px;
      width: 20px;
    }
    &:hover {
      background-color: #BFB0A5;
      &:before {
        border-color: #fff;
      }
    }
  }
  .slick-prev:before, .slick-next:before {
    content: '';
    display: block;
    margin: auto;
    width: 12px;
    height: 12px;
    border-left: 1px solid #BFB0A5;
    border-bottom: 1px solid #BFB0A5;
    position: relative;
  }
  .slick-prev:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 2px;
  }
  .slick-next:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    right: 2px;
  }
  .slick-prev {
    left: -35px;
    @media screen and (max-width: 500px) {
      left: -15px;
    }
  }
  .slick-next {
    right: -35px;
    @media screen and (max-width: 500px) {
      right: -15px;
    }
  }
}

app-oap-mbl-catalog {

}

.card-nyx {
  display: table;
  width: 100%;
  height: 100%;
  .card_left {
    @media screen and (max-width: 1024px) {
      width: 40%;
    }
    @media screen and (max-width: 835px) {
      margin: 0 0 45px 0;
    }
    .card_row {
      display: table-row;
    }
    .card_img {
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      width: 280px;
      position: relative;
      @media screen and (max-width: 1024px) {
        width: 100%;
        display: table;
        height: 50%;
      }
      @media screen and (max-width: 835px) {
        height: 200px;
      }
      img{
        // max-width: 90%;
        margin: 0 auto;
        max-width: 270px;
        max-height: 90%;
        @media screen and (max-width: 1024px) {
          max-width: 90px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }
    .card_title {
      color: #fff;
      margin: 0 auto;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
      font-size: 25px;
      text-transform: uppercase;
      line-height: 1.6;
      padding: 0 20px 0 0;
      @media screen and (max-width: 1024px) {
        font-size: 18px;
        display: table;
        padding: 0;
        text-align: center;
        height: 50%;
        padding: 60px 0 0 0;
      }
      @media screen and (max-width: 835px) {
        font-size: 16px;
        padding: 30px 0 0 0;
        height: auto;
      }
    }
  }
  .card_right {
    padding: 0 0 0 120px;
    @media screen and (max-width: 1024px) {
      padding: 0 0 0 75px;
      width: 60%;
    }
    @media screen and (max-width: 835px) {
      text-align: center;
      padding: 0;
    }
    .card_ul {
      text-transform: uppercase;
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      .card_ul_title {
        padding: 0 0 45px 12px;
        font-size: 18px;
        color:#e5007f;
        @media screen and (max-width: 835px) {
          padding: 0 0 45px 0;
        }
      }
      .carousel{
        position: relative;
        .slide{
          padding: 0 40px 0 0;
          outline: none;
          width: 100% !important;
          margin: 0;
          @media screen and (max-width: 835px) {
            padding: 0;
          }
          a{
            color:#ffffff;
            text-decoration: none;
            padding: 0 12px;
            //padding: 10px 12px;
            display: block;
            margin: 0;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            @media screen and (max-width: 835px) {
              margin: 0 auto;
              height: auto;
              line-height: 1.2;
              padding: 10px 12px;
            }
            &:hover, &.active {
              background-color: #ed2891;
            }
          }
        }
      }
      .card_ul_prev, .card_ul_next {
        outline: none;
        cursor: pointer;
        width: 43px;
        height: 43px;
        border-radius: 100%;
        border: none;
        position: absolute;
        top:33%;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
      }
      .card_ul_prev:hover, .card_ul_next:hover {
        background-color: #ed2891;
      }
      .card_ul_prev {
        background: #2c2929 url("/assets/images/left.png") no-repeat 45% 50%;
        left: -120px;
      }
      .card_ul_next {
        background: #2c2929 url("/assets/images/right.png") no-repeat 55% 50%;
        right:0;
      }
    }

  }
  .card_side {
    width: 50%;
    display: table;
    height: 100%;
    float: left;
    @media screen and (max-width: 835px) {
      width: 100%;
      height: auto;
    }
  }
}

/* Скроллер в catalogs-list */
.scroll_list {
  width: 100%;
  height: 500px !important;

  @media screen and (max-width: 500px) {
    height: 100%;
  }
}
.scroll_list_catalog {
  width: 100%;
  height: 300px !important;
}
/* Catalog (конец) */


app-calendar {
  width: 100%;
}

/* Таймер в game-moderator (начало) */
.custom_timer {
  mat-progress-spinner {
    box-shadow: 0 0 0 30px #dbdbdb inset;
    border-radius: 50%;

    @media screen and (max-width: 1024px) {
      width: 250px !important;
      height: 250px !important;
    }
  }

  mat-progress-spinner svg {
    @media screen and (max-width: 1024px) {
      width: 250px !important;
      height: 250px !important;
    }
  }

  .mat-progress-spinner circle {
    @media screen and (max-width: 1024px) {
      stroke-width: 15% !important;
    }
  }

  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #8a6695;
  }
}
/* Таймер в game-moderator (конец) */

.checkbox_basket {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 100%;
  .mat-checkbox-inner-container {
    height: 30px;
    width: 30px;
    .mat-checkbox-frame {
      border-radius: 100%;
    }
    .mat-checkbox-background {
      border-radius: 100%;
    }
  }
}

.no-bg-dialog {
  .mat-dialog-container {
    background: none !important;
    box-shadow: none !important;
  }
}

.no-overflow-dialog {
  .mat-dialog-container {
    overflow: unset;
  }
}

.eco-portal-inner-page {
  display: block;
  height: 100%;
}

app-kpi-preview-card {
  app-kpi-progress-bar {
    .progress_bar {
      width: 83%!important;
    }
    .percent_text {
      width: 17%!important;
    }
  }
}

.cdk-overlay-pane {
  @media screen and (max-width: 650px) {
    max-width: 100%!important;
  }
}

.app-button {
  //min-width: 130px !important;
  min-width: 236px !important;
  height: 62px;
  padding: 0 30px !important;
  font-family: montBold, sans-serif;
  font-size: 16px;
  @include rounded.full;

  &.primary {
    background-image: linear-gradient(88deg, #776ffd 0%, #664ae8 100%);
    color: white;
  }
}
