@keyframes bounce {
    0% {
        width: 24px;
        height: 24px;
    }
    100% {
        width: 100%;
        height: 100%;
    }
}

@keyframes bounceSvg {
    0% {
        width: 0;
        height: 0;
    }
    100% {
        min-width: 100%;
        min-height: 100%;
    }
}


@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes drive {
    0% {
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
    }
    100% {
        width: 0;
        background: #B09D8C;
        opacity: 0;
    }
}

@keyframes swipeLeft {
    0% {
        transform: translate(100%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes swipeRight {
    0% {
        transform: translate(-100%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes swipeDown{
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes swipeTop{
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}

  
@keyframes bounceItem {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
}