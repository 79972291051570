@font-face {
  font-family: sfuidisplayBlack;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-black.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayBold;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-bold.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayHeavy;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-heavy.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayLight;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-light.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayMedium;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-medium.woff') format("woff");
}

@font-face {
  font-family: sfuidisplaySemibold;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-semibold.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayThin;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-thin.woff') format("woff");
}

@font-face {
  font-family: sfuidisplayUltralight;
  src: url('/assets/fonts/sfuidisplay/sf-ui-display-ultralight.woff') format("woff");
}

@font-face {
  font-family: montBold;
  src: url('/assets/fonts/mont/Mont-Bold.ttf') format("truetype");
}

@font-face {
  font-family: montExtraLight;
  src: url('/assets/fonts/mont/Mont-ExtraLight.ttf') format("truetype");
}

@font-face {
  font-family: montHeavy;
  src: url('/assets/fonts/mont/Mont-Heavy.ttf') format("truetype");
}

@font-face {
  font-family: montRegular;
  src: url('/assets/fonts/mont/Mont-Regular.ttf') format("truetype");
}
